.container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #d9edff;
}
.submitButton {
  background-color: #573BD3 !important;
  border-color: #573BD3 !important;
  border-radius: 4px !important;
}
.signTitle {
  margin-top: 1rem;
  font-weight: bold;
}
.signWarning {
  color: red;
}
.signPadWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  @media (max-width: 578px) {
    flex-direction: column;
    align-items: flex-start;
  }
}
.clearIcon {
  margin-left: 5px;
  @media (max-width: 578px) {
    width: 100%;
    margin-left: 0;
    margin-top: 5px;
  }
}
