.container {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #dde7ff;
  flex-direction: column;
}
.loginBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row-reverse;
  max-width: 1000px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0,0,0,0.16);
  overflow: hidden;
  margin: 0 auto;
  border-radius: 4px;
  width: 600px;
}
.logoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 30px;
}
.loginForm {
  flex: 1 0 100%;
  width: 100%;
  padding: 60px;
}
.submitButton {
  width: 100%;
  background-color: #573BD3;
  border-color: #573BD3;
  border-radius: 4px;
}
.inputLabel {
  margin-bottom: 0px;
}
.separator {
  margin: 0 0.7rem;
}
.otp_container_styles {
  display: flex;
  justify-content: flex-start;
  padding: 20px 0;
}
.otp_input_styles_safari {
  width: 36px !important;
  height: 36px !important;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
  padding: 0 0 0.3rem 0;
}
.otp_input_styles {
  width: 36px !important;
  height: 36px !important;
  font-size: 1.5rem;
  border-radius: 4px;
  border: 1px solid #d9d9d9;
}
.desc {
  font-weight: 300;
  color: rgba(85, 85, 85, 85);
  width: 500px;
  font-size: 1.5rem;
}
.resend_button {
  margin-left: 1rem;
  font-weight: 500;
  color: #573bd3 !important;
}
.footer {
  padding: 20px;
  font-weight: bold;
}
@media only screen and (max-width: 600px) {
  .otp_input_styles {
    width: 30px !important;
    height: 30px !important;
    font-size: 1rem;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
  }
  .otp_container_styles {
    display: flex;
    justify-content: flex-start;
    padding: 20px 0;
  }
  .separator {
    margin: 0 0.3rem;
  }
  .desc {
    width: 100%;
  }
}