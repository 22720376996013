.signCanvas {
  border: 1px solid lightgrey;
  border-radius: 2px;
  width: 500px;
  height: 200px;
  user-select: none;
}

@media (max-width: 578px) {
  .signCanvas {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .signCanvas {
    width: 400px;
    height: 160px;
  }
}

@media (max-width: 450px) {
  .signCanvas {
    width: 100%;
    height: 120px;
  }
}